import SmoothScroll from 'smooth-scroll';
import * as AOS from 'aos/dist/aos.js';
import { up, down } from "slide-element";

document.querySelector('.burger').addEventListener('click', function () {
	if (document.querySelector('header .left.show')) {
		document.querySelector('header .left').classList.add('hide');
		document.querySelector('header .left ul').classList.remove('active');
		document.querySelector('header .left').classList.remove('show');
	} else {
		document.querySelector('header .left').classList.add('show');
		setTimeout(function () {
			document.querySelector('header .left ul').classList.add('active');
		},200)
		document.querySelector('header .left').classList.remove('hide');
	}
})

var scroll = new SmoothScroll('a[href*="#"]', {
	speed: 500,
	speedAsDuration: true
});

AOS.init();

const faqButtons = document.querySelectorAll('section.four .head');

faqButtons.forEach(button => {
	button.addEventListener('click', () => {
		const isActive = button.closest('.item').classList.contains('active');

		faqButtons.forEach(btn => btn.closest('.item').classList.remove('active'));

		if (!isActive) {
			setTimeout(() => {
				down(button.closest('.item').querySelector('.text'), {
					duration: 500,
				});
				button.closest('.item').classList.add('active');
			}, 50);
		}
		
		faqButtons.forEach(btn => up(btn.closest('.item').querySelector('.text'), {
			duration: 500,
		}));
		
	});
});


var viewport_meta = document.querySelector('[name=viewport]');

var viewports = {
	default: viewport_meta.getAttribute('content'),
	mobile: 'width=360'
};

var viewport_set = function() {
	if (screen.width < 768) {
		viewport_meta.setAttribute('content', viewports.mobile);
	} else {
		viewport_meta.setAttribute('content', viewports.default);
	}
}

viewport_set();

window.onresize = function() {
	viewport_set();
}
